jQuery(document).ready(function ($) {

  if ($('.home').length) {
    // covid banner slider
    $('#covid_banner_slider').lightSlider({
      item          : 1,
      loop          : true, 
      controls      : true,
      pager         : false,
      adaptiveHeight: false,
      enableDrag    : false,
      nextHtml      : "<i class='ci ci_arrow_right'>&nbsp;</i>",
      mode          : 'fade'
    })

    // what's happending section
    const theHappening = (() => {
      // init data
      const sliderArgs = {
        item          : 1,
        loop          : true,
        controls      : true,
        pager         : false,
        adaptiveHeight: false,
        enableDrag    : false,
        nextHtml      : "<i class='ci ci_arrow_right'>&nbsp;</i>",
        mode          : 'fade'
      }

      let sliders = {
        'news'    : null,
        'events'  : null,
        'facebook': null
      }

      // slider trigger clicked
      $('.the_trigger').on('click', (e) => {
        const self            = $(e.target),
              sliderToDisplay = self.text(),
              sliderWrap      = $('.h_slider_wrap')
        
        // modify the state of slider(s)
        if (!self.hasClass('active')) {
          switch (true) {
            // news
            case sliderToDisplay == 'News':
              sliderWrap.not('.news_slider').parents('.lSSlideOuter').fadeOut(0)
              $('.social_slider').fadeOut(0)
              
              if (sliders.events != null) {
                sliders.events.destroy()
              }
  
              if (sliders.facebook != null) {
                sliders.facebook.destroy()
              }
  
              sliders.news = $('.news_slider').lightSlider(sliderArgs)
              $('.news_slider').parents('.lSSlideOuter').fadeIn(300)               
            break;
            
            // events
            case sliderToDisplay == 'Events':
              sliderWrap.not('.events_slider').parents('.lSSlideOuter').fadeOut(0)
              $('.social_slider').fadeOut(0)
            
              if (sliders.news != null) {
                sliders.news.destroy()
              }
              
              if (sliders.facebook != null) {
                sliders.facebook.destroy()
              }
              
              sliders.events = $('.events_slider').lightSlider(sliderArgs)
              $('.events_slider').parents('.lSSlideOuter').fadeIn(300)
            break;
            
            // facebook
            case sliderToDisplay == 'Facebook':
              sliderWrap.not('.social_slider').parents('.lSSlideOuter').fadeOut(0)
              $('.social_slider').fadeOut(0)
  
              if (sliders.news != null) {
                sliders.news.destroy()
              }

              if (sliders.events != null) {
                sliders.events.destroy()
              }
              
              $('.social_slider').fadeIn(300)              
            break;
          }
        }

        // toggle classes depending on the active slider
        $('.the_trigger').removeClass('active')
        self.addClass('active')        
      })      

      // init news slider
      sliders.news = $('.news_slider').lightSlider(sliderArgs)
      $('.news_slider').parents('.lSSlideOuter').fadeIn(300)
    })()
  }

})